import { useEffect, useState } from 'react';

/**
 * Note: Need to sync scss variables `mq-breakpoints`
 */
export const mediaBreakpoints = {
  mobile: '320px',
  'small-tablet': '576px',
  tablet: '768px',
  'small-desktop': '992px',
  desktop: '1000px',
  wide: '1190px',
  'extra-wide': '1280px',
  'extra-extra-wide': '1366px',
};

/**
 * Equivalent of css's @media (returns a boolean if it's matching)
 * @example
 * const matches = useMediaQuery("(max-width: 1024px)");
 */
export function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(false);

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}

export default useMediaQuery;
