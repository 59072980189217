import { useState } from 'react';

export type Optional<T extends object> = {
  [K in keyof T]?: T[K];
};

export const useStateHandlers = <T extends object>(
  initialState: T,
): [
  T,
  (
    newState: Optional<T> | ((prevState: T) => Optional<T>),
    callback?: (prevState, newState: Optional<T>) => void,
  ) => void,
] => {
  const [state, setState] = useState<T>(initialState);

  const setNewState = (
    newState: Optional<T> | ((prevState: T) => Optional<T>),
    callback?: (prevState, newState: Optional<T>) => void,
  ): void =>
    setState((prevState) =>
      typeof newState === 'function'
        ? { ...prevState, ...newState(prevState) }
        : { ...prevState, ...newState },
    );

  return [state, setNewState];
};
