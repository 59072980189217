import { useContext } from 'react';
import DictionaryContext from 'context/Dictionary/DictionaryContext';
import { TranslateContext } from 'context/Translate';

/**
 * `useTranslation` is the locale hook that allows
 * to use translations from storage/file.
 *
 * It return the current selected language
 * and a `t` function that gets a key as param.
 *
 * @example
 * const { locale, t } = useTranslation(['common']);
 *
 */
export function useTranslation(ns: string[] = []) {
  const { locale, store, loading } = useContext(DictionaryContext);
  const { onUnknownNameSpace, isLoading } = useContext(TranslateContext);

  function t(key: string): string {
    let [nameSpace] = ns;

    if (key.indexOf(nsSeparator) > -1) {
      [nameSpace, key] = key.split(nsSeparator);
    }

    if (!isLoading && !loading && !store[locale][nameSpace]) {
      if (process.env.NODE_ENV !== 'production') {
        onUnknownNameSpace(nameSpace, key);

        // tslint:disable-next-line: no-console
        console.warn(`Namespace "${nameSpace}" wasn't loaded or doesnt exists!`);
      }
      return key;
    }

    if (
      process.env.NODE_ENV !== 'production' &&
      !isLoading &&
      !loading &&
      !store[locale][nameSpace][key]
    ) {
      onUnknownNameSpace(nameSpace, key);
    }

    return (!loading && store[locale][nameSpace][key]) || key;
  }

  return {
    locale,
    t,
  };
}

const nsSeparator = '.';

/**
 *
 * Here is a component that displays the current language
 * and it's name by key with `t` function.
 *
 * @example
 *
 * import React from 'react'
 * import useTranslation from 'translations/useTranslations'
 *
 * const CurrentLanguage: React.FC = () => {
 *   const { locale, t } = useTranslation(["common"])
 *
 *   return (
 *     <div>
 *       <p>Current language is: {locale} {t(locale)}</p>
 *     </div>
 *   )
 * }
 *
 * export default CurrentLanguage
 */
