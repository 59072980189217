import { createContext, useEffect, useCallback, useMemo } from 'react';
import { Article, Properties } from 'types';
import { useStateHandlers } from 'hooks';

interface Props {
  url: string;
  menu?: Article[];
  isMobile: boolean;
  isDeploy: boolean;
  setState: (data: Properties) => void;
}

const defaultStore = {
  url: '',
  isMobile: true,
  isDeploy: false,
};

export const StoreContext = createContext<Props>({
  ...defaultStore,
  setState: () => null,
});

const StoreProvider = ({ children }) => {
  const [state, setState] = useStateHandlers({ ...defaultStore });

  useEffect(() => {
    setState({ url: window?.location.href, isMobile: window?.innerWidth < 934 });
  }, []);

  const setStateHandler = useCallback((data: Properties) => setState(data), []);

  return (
    <StoreContext.Provider
      value={{
        ...state,
        setState: setStateHandler,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
