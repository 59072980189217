import { useMemo } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import {
  AddToMarketCart,
  ECommerceType,
  MarketProduct,
  PeriodicEdition,
  PeriodicEditionCartItemPost,
} from 'types';

interface AllDetailItem<T> {
  productObject: T;
}

export interface ProductLocalStorage extends AddToMarketCart, AllDetailItem<MarketProduct> {}

export interface PeriodicEditionsLocalStorage
  extends PeriodicEditionCartItemPost,
    AllDetailItem<PeriodicEdition> {}

type Options<R> = Omit<
  UseQueryOptions<
    unknown,
    unknown,
    {
      cart: R;
      quantity: number;
    }
  >,
  'queryKey' | 'queryFn'
>;

interface UseProductToCart<R> {
  productId?: number;
  options?: Options<R>;
  redirect?: string;
  type?: ECommerceType;
  searchThisElement?: number;
}

export const useProductToCart = <
  R extends (ProductLocalStorage | PeriodicEditionsLocalStorage)[] = ProductLocalStorage[],
  T extends (ProductLocalStorage & PeriodicEditionsLocalStorage)[] = (ProductLocalStorage &
    PeriodicEditionsLocalStorage)[],
>(
  props?: UseProductToCart<R>,
) => {
  const {
    productId,
    options,
    redirect,
    type = 'market',
    searchThisElement,
  } = props
    ? props
    : {
        productId: undefined,
        options: undefined,
        redirect: undefined,
        type: undefined,
        searchThisElement: undefined,
      };
  const key = `product-${type}-cart`;
  const queryKey = productId ? [key, productId] : key;
  const idKey = type === 'market' ? 'product' : 'edition_variation';
  const searchID = searchThisElement || productId;

  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery(
    queryKey,
    () => {
      const itemsLocalStorage: T = JSON.parse(localStorage.getItem(key) || '[]');

      let quantity = 0;

      if (type === 'market') {
        quantity = itemsLocalStorage.find((item) => item.product === productId)?.quantity || 0;
      } else if (type === 'periodic-editions') {
        quantity =
          itemsLocalStorage.find((item) => item.edition_variation === productId)?.quantity || 0;
      }

      return {
        cart: itemsLocalStorage,
        quantity,
      };
    },
    {
      ...options,
      onSuccess: (data) => {
        if (!data?.cart?.length && !rest.isLoading && redirect) {
          // if use router.push set infinite rerender
          window.location.href = window.location.origin + redirect;
        }
        options?.onSuccess(data);
      },
    },
  );

  const { data: dynamicQty } = useQuery(
    queryKey + 'dynamic-qty-cart',
    () => {
      return data?.quantity;
    },
    {
      enabled: !!data?.quantity,
    },
  );

  const addProductToCart = (
    product: number,
    quantity: number = 1,
    productObject: MarketProduct | PeriodicEdition,
    months?: number[],
  ) => {
    const marketBuy: ProductLocalStorage = {
      product,
      quantity,
      productObject: productObject as MarketProduct,
    };

    const editionPeriodicBuy: PeriodicEditionsLocalStorage = {
      edition_variation: product,
      quantity,
      months,
      productObject: productObject as PeriodicEdition,
    };

    const productBuy = type === 'market' ? marketBuy : editionPeriodicBuy;

    const items = data?.cart || ([] as R);

    let newItems = [];

    const id = productObject?.id || product;

    if (!items.length) {
      quantity && newItems.push(productBuy);
    } else {
      newItems = quantity
        ? items.some((item) => item.productObject.id === id)
          ? items.map((item) => (item.productObject.id === id ? productBuy : item))
          : [...items, productBuy]
        : [...items.filter((item) => item.productObject.id !== id)];
    }

    localStorage.setItem(key, JSON.stringify(newItems));
    queryClient.invalidateQueries(key);
  };

  const deleteProductFromCart = (product: number) => {
    addProductToCart(product, 0, data?.cart.find((i) => i[idKey] === product)?.productObject);
  };

  const setDynamicQty = (qty: number) => {
    queryClient.setQueryData(queryKey + 'dynamic-qty-cart', qty);
  };

  return useMemo(
    () => ({
      addProductToCart,
      deleteProductFromCart,
      quantity: data?.quantity,
      cart: data?.cart || ([] as R),
      options: rest,
      setDynamicQty,
      dynamicQty,
      searchThisElement: data?.cart?.find((item) => item?.productObject?.id === searchID) as
        | R[number]
        | undefined,
    }),
    [data, dynamicQty],
  );
};
