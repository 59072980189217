import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import dynamic from 'next/dynamic';
import { dictionary } from 'api';
import DictionaryContext from 'context/Dictionary/DictionaryContext';
import { DictionaryStoreType, locales } from 'context/Dictionary';
import { cacheTime } from 'utils';
import type { Dictionary, Locale } from 'types';

import { defaultDictionaries } from './Dictionary/DictionaryContext';
import { ActionType } from './Dictionary/utils';

const Translate = dynamic(() => import('components/Translate'));

const enabled = process.env.NODE_ENV !== 'production';

export interface Translate {
  count: number;
  isLoading: boolean;
  unknownNameSpaces: DictionaryStoreType;
  onUnknownNameSpace: (
    namespace: string,
    key: string,
    data?: { value: string; lang: Locale },
  ) => void;
  onSave: () => void;
  onReset: () => void;
}

const defaultValue = {
  count: 0,
  isLoading: false,
  unknownNameSpaces: { ...defaultDictionaries },
  onUnknownNameSpace: () => null,
  onSave: () => null,
  onReset: () => null,
};

export const TranslateContext = createContext<Translate>(defaultValue);

type StoreType = { count: number; data: typeof defaultDictionaries };

const TranslateProvider = ({ children }: { children: ReactNode }) => {
  const { locale, dispatch, store } = useContext(DictionaryContext);
  const [count, setCount] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [unknownNameSpaces, setUnknownNameSpaces] = useState({ ...defaultDictionaries });

  useEffect(() => {
    let store: StoreType | string | null = localStorage?.getItem('translations');

    if (typeof window !== 'undefined' && store) {
      store = JSON.parse(store);
      setCount((store as StoreType).count);
      setUnknownNameSpaces((store as StoreType).data);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('translations', JSON.stringify({ data: unknownNameSpaces, count }));
    }
  }, [unknownNameSpaces, count]);

  const { data = {}, isLoading: isLoadingTranslations } = useQuery(
    ['translations'],
    (key) => dictionary.getList(key, { 'Accept-Language': 'no' }),
    {
      enabled: submit,
      cacheTime,
      select: (response) => {
        let i18nData = {};

        response.results.forEach((item) => {
          let i18n = {};

          Object.keys(item.i18n).forEach((i) => {
            i18n[i] = JSON.parse(item.i18n[i]);
          });

          i18nData[item.text_id] = {
            id: item.id,
            i18n,
          };
        });

        return i18nData;
      },
    },
  );

  const { mutate, isLoading } = useMutation(
    (params: Partial<Dictionary>) =>
      dictionary.updatePartial(params, {
        Authorization: process.env.NEXT_PUBLIC_API_TOKEN,
        'Saas-app-token': process.env.NEXT_PUBLIC_API_saasAppToken,
      }),
    {
      onSuccess: (response) => {
        dispatch({
          type: ActionType.REPLACE,
          dictionary: {
            [locale]: { ...store[locale], [response.text_id]: JSON.parse(response.i18n[locale]) },
            ...locales.filter((i) => i !== locale).reduce((a, v) => ({ ...a, [v]: {} }), {}),
          } as DictionaryStoreType,
        });
      },
    },
  );

  const onUnknownNameSpace = useCallback(
    (namespace: string, key: string, data?: { value: string; lang: Locale }) => {
      if (
        !data &&
        (!unknownNameSpaces[locale][namespace] || !unknownNameSpaces[locale][namespace][key])
      ) {
        setUnknownNameSpaces((prevState) => {
          if (
            prevState[locale] &&
            (!(namespace in prevState[locale]) || !(key in prevState[locale][namespace]))
          ) {
            setCount((prevState) => prevState + 1);
          }

          return {
            ...prevState,
            [locale]: {
              ...prevState[locale],
              [namespace]: {
                ...prevState[locale][namespace],
                [key]: key,
              },
            },
          };
        });
      } else if (data) {
        setUnknownNameSpaces((prevState) => ({
          ...prevState,
          [data.lang]: {
            ...prevState[data.lang],
            [namespace]: {
              ...prevState[data.lang][namespace],
              [key]: data.value,
            },
          },
        }));
      }
    },
    [unknownNameSpaces],
  );

  const onSave = useCallback(() => {
    let i18nData = {};

    if (!submit) {
      setSubmit(true);
    } else if (Object.keys(data).length) {
      Object.keys(data).forEach((item) => {
        let i18n = {};

        Object.keys(data[item].i18n).forEach((i) => {
          i18n[i] = {
            ...unknownNameSpaces[locale][item],
            ...data[item].i18n[i],
          };
        });

        i18nData[item] = {
          ...data[item],
          i18n,
        };
      });

      Object.keys(unknownNameSpaces[locale]).forEach((i) => {
        mutate({
          id: i18nData[i].id,
          i18n: i18nData[i].i18n,
        });
      });

      setSubmit(false);
      setCount(0);
      setUnknownNameSpaces({ ...defaultDictionaries });
      localStorage.setItem(
        'translations',
        JSON.stringify({ data: { ...defaultDictionaries }, count: 0 }),
      );
    }
  }, [submit, data, unknownNameSpaces, mutate]);

  useEffect(() => {
    if (submit) {
      onSave();
    }
  }, [submit, onSave]);

  const onReset = useCallback(() => {
    setCount(0);
    setUnknownNameSpaces({ ...defaultDictionaries });
  }, []);

  return (
    <TranslateContext.Provider
      value={{
        count,
        isLoading: isLoading || isLoadingTranslations,
        unknownNameSpaces,
        onUnknownNameSpace,
        onSave,
        onReset,
      }}
    >
      {enabled && <Translate />}
      {children}
    </TranslateContext.Provider>
  );
};

export default TranslateProvider;
