/**
 * useUpload is used to get common upload props
 */
export const useUpload = (): {
  name: string;
  action: string;
  data: { [key: string]: any };
} => {
  return {
    data: { public: false },
    name: 'upload',
    action: `${process.env.NEXT_PUBLIC_API_URL}v2/attachments/`,
  };
};
